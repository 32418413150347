import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '@/views/m/header';
import Footer from '@/views/m/footer';
import DownloadBtn from '@/views/m/downloadBtn';
import oneDImg from '@/assets/home/result/交通运输1@2x.png';
import oneHImg from '@/assets/home/result/交通运输2@2x.png';
import twoDImg from '@/assets/home/result/石油1.png';
import twoHImg from '@/assets/home/result/石油石化1@2x.png';
import threeDImg from '@/assets/home/result/电力通讯1@2x.png';
import threeHImg from '@/assets/home/result/电力通讯2.png';
import fourDImg from '@/assets/home/result/危险作业1@2x.png';
import fourHImg from '@/assets/home/result/危险作业2@2x.png';
import fiveDImg from '@/assets/home/result/航天军工1@2x.png';
import fiveHImg from '@/assets/home/result/航天军工2@2x.png';
import sixDImg from '@/assets/home/result/仓储安全1@2x.png';
import sixHImg from '@/assets/home/result/仓储安全2@2x.png';
import smallOne from '@/assets/home/result/one.png';
import smallTwo from '@/assets/home/result/two.png';
import smallThree from '@/assets/home/result/three.png';
import smallFour from '@/assets/home/result/four.png';
import smallFive from '@/assets/home/result/five.png';
import smallSix from '@/assets/home/result/six.png';
import leftBtn from '@/assets/home/result/left.png';
import rightBtn from '@/assets/home/result/rig.png';
import lockimg from '@/assets/m/lock.png';
import lock2img from '@/assets/m/lock2.png';

import './index.css';

const AccordionItem = ({ data, index, isActive, onHover }) => {
  return (
    <div
      className={`accordion-item ${isActive ? 'active' : ''}`}
      onMouseEnter={() => onHover(index)}
      onMouseLeave={() => onHover(null)}
    >
      <div
        className="default-image"
        style={{ backgroundImage: `url(${data.defaultImg})` }}
      >
        <div className="d-title-ctn">
          <div className="d-enTitle">{data.enTitle}</div>
          <div className="d-cnTitle">{data.cnTitle}</div>
        </div>
        <img src={data.smallImg} className="smallIcon" />
      </div>

      <div
        className="hover-image"
        style={{ backgroundImage: `url(${data.hoverImg})` }}
      >
        <div className="title-ctn">
          <div className="enTitle">{data.enTitle}</div>
          <div className="cnTitle">{data.cnTitle}</div>
        </div>
        <div
          className="ctn-desc"
          dangerouslySetInnerHTML={{ __html: data.desc }}
        ></div>
        <img src={data.smallImg} className="smallIcon" />
      </div>
    </div>
  );
};

const Reult = () => {
  const homeTwoRef = useRef(null);
  const [twoTopHeight, setTwoTopHeight] = useState(0);
  const [animate, setAnimate] = useState(true);
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);
  const [startIndex, setStartIndex] = useState(0);

  const [sfqData, setSfqData] = useState([
    {
      defaultImg: oneDImg,
      hoverImg: oneHImg,
      enTitle: 'Transportation industry',
      cnTitle: '交通运输行业',
      desc: '在铁路、航空、远洋、公路运输过程中对集装箱、包装柜、重点设备等均需要使用锁具进行安全保障。',
      smallImg: smallOne,
    },
    {
      defaultImg: twoDImg,
      hoverImg: twoHImg,
      enTitle: 'Oil and petrochemical industry',
      cnTitle: '石油石化行业',
      desc: '在油田生产管理中，通过锁具对重点要害部位挂牌上锁。如输气输油管线阀门、控制柜、计量仪表校验、重点测、钻、采设备的管理。<br/> 对无人值守场站、机房、电力设备的防盗防拆以及油气输运环节中的防盗措施，均需要使用具 有无线报警功能的施封锁，确保正常运行。',
      smallImg: smallTwo,
    },
    {
      defaultImg: threeDImg,
      hoverImg: threeHImg,
      enTitle: 'Power and telecommunications',
      cnTitle: '电力通讯行业',
      desc: '无人值守输变电站、无人值守通讯基站数量庞大。在传统防盗防破坏的安全需求之外，远程授权管理开锁功能将使生产管理更加便捷。',
      smallImg: smallThree,
    },
    {
      defaultImg: fourDImg,
      hoverImg: fourHImg,
      enTitle: 'Hazardous operations sector',
      cnTitle: '危险作业领域',
      desc: '各类施工作业现场，需要关断各类阀门或电闸才能具备施工条件，这些阀闸在施工过程中是绝对禁止开启的。使用具有无线报警功能的施封锁，能够进一步加强现场管理，一旦有违规开启发生即可无线预警，避免安全事故的发生。',
      smallImg: smallFour,
    },
    {
      defaultImg: fiveDImg,
      hoverImg: fiveHImg,
      enTitle: 'Aerospace and military industry',
      cnTitle: '航天军工领域',
      desc: '针对航天、军工、军队等保密级别较高的场所，锁具既要确保其严格的保密性，又要确保在充分授权后的及时开启，尤其在武器库等重点保障场所，多人授权开锁管理要求等级高具有无线报警功能的施封锁能够高效、安全的发挥辅助管理作用。',
      smallImg: smallFive,
    },
    {
      defaultImg: sixDImg,
      hoverImg: sixHImg,
      enTitle: 'Warehouse safety',
      cnTitle: '仓储安全',
      desc: '通过智能报警施封锁管控库房，避免非授权人员进入库房。一旦锁具遭到破坏立即发出报警信息，保障物资安全。对于重要物资库房，实行授权开锁模式。库房需要多人授权才能开启，确保重要物资安全。',
      smallImg: smallSix,
    },
  ]);

  const handleHover = (index) => {
    setActiveIndex(index !== null ? index : startIndex);
  };

  const handleNext = () => {
    setStartIndex((prevIndex) => (prevIndex + 1) % sfqData.length);
  };

  const handlePrev = () => {
    setStartIndex((prevIndex) =>
      prevIndex === 0 ? sfqData.length - 4 : prevIndex - 1
    );
  };

  const goDownloadPage = () => {
    navigate('/m/downloadpage');
  };

  useEffect(() => {
    document.title = '鸿凯瑞达-产品';
    // const { top } = homeTwoRef.current.getBoundingClientRect();
    // setTwoTopHeight(top);
  }, []);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const scrollDistance = window.scrollY;

  //     if (scrollDistance >= twoTopHeight && !animate) {
  //       // 滚动距离达到了指定值，设置状态以触发动画
  //       setAnimate(true);
  //     } else {
  //       // 如果滚动距离小于阈值，重置状态
  //       setAnimate(false);
  //     }
  //   };

  //   // 添加滚动事件监听器
  //   window.addEventListener('scroll', handleScroll);

  //   // 组件卸载时移除监听器
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);

  return (
    <div>
      <div className="home-one">
        <Header pageName="result" />
        <div className="m-bg-top-img">
          <img src={lockimg} className="lockimg" />
          <div className="cent-text">
            <div className="en-title">Intelligent alarm sealing lock</div>
            <div className="img-text">智能报警施封锁</div>
            <div className="desc">
              “鸿凯瑞达智能报警施封锁“是一款具有智能报警且可反复使用的施封锁，他解决了传统施封锁不能重复使用，不能及时获知
              锁具的状态，非法损坏不能及时知晓，无法进行多级授权开锁，无法确认操作人身份工作过程无法跟踪等弊端，是一款安全性极高的产品。
            </div>
            <div className="zlh-m">专利号：ZL202121275780.2</div>
          </div>
        </div>
        <div className="m-bg-two-img">
          <img src={lock2img} className="lockimg2" />
          <div className="m-right-ctn-four">
            <div className="en-title">Technical parameters</div>
            <div className="title">智能报警施封锁技术参数</div>
            <div className="title-line"></div>
            <div className="ctn">
              产品采用全密封式设计，不锈钢材质具有出色的强度，具备IP65防护等级。智能锁按照危险环境防爆电器规范设计要求通过了防爆等认证，防爆等级为：本安Ex
              ibⅡIC T3 Gb。
            </div>
            <div className="ctn" style={{ marginTop: 3 }}>
              同时智能锁依照军用设备检测标准进行了检测，并通过了国际互认的CNAS认证，确保在多种应用场景下的卓越表现。无论是日常使用还是专业需求，本产品都能满足您的期望
            </div>
            <div className="disflex">
              <div className="result-js"></div>
            </div>
          </div>
        </div>
        <div className="m-bg-three-img">
          <div className="en-title">Application field</div>
          <div className="title">多个应用领域</div>
          <div className="title-line"></div>
          <div style={{ position: 'relative' }}>
            {startIndex !== 0 && (
              <img src={leftBtn} className="leftBtn" onClick={handlePrev} />
            )}
            <div className="accordion">
              {sfqData
                .slice(startIndex, startIndex + 4)
                .map((reward, index) => (
                  <AccordionItem
                    key={index}
                    index={index}
                    isActive={activeIndex === index}
                    onHover={handleHover}
                    data={reward}
                  />
                ))}
            </div>
            {startIndex < sfqData.length - 4 && (
              <img src={rightBtn} className="rightBtn" onClick={handleNext} />
            )}
          </div>

          <div className="en-title" style={{ marginTop: 80 }}>
            Product features
          </div>
          <div className="title">产品特点</div>
          <div className="title-line"></div>
        </div>
      </div>

      <DownloadBtn />
      <Footer />
    </div>
  );
};

export default Reult;
